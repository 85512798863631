import React from 'react';
import David from '../components/David';

const DavidPage = () => {
  return (
    <div>
      <David/>
    </div>
  );
}

export default DavidPage;
