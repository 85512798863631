import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import JemimaPage from "./pages/JemimaPage";
import BernicePage from "./pages/BernicePage";
import CalebPage from "./pages/CalebPage";
import BookingsPage from "./pages/BookingsPage";
import QuotationsPage from "./pages/QuotationsPage";
import BieberPage from "./pages/BieberPage";
import ToluPage from "./pages/ToluPage";
import DavidPage from "./pages/DavidPage"

function App() {
  return (
    <main>
      <Router>
        <ScrollToTop />

        <Layout>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/jemima" element={<JemimaPage />} />
              <Route path="/bernice" element={<BernicePage />} />
              <Route path="/bieba" element={<BieberPage />} />
              <Route path="/tolu" element={<ToluPage />} />
              <Route path="/david" element={<DavidPage />} />
              <Route path="/caleb" element={<CalebPage />} />
              <Route path="/bookings" element={<BookingsPage />} />
              <Route path="/quotations" element={<QuotationsPage />} />
          </Routes>
        </Layout>
      </Router>
    </main>
  );
}

export default App;
