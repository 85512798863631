import React from "react";
import davidbig from "../assets/davidbig.png";
const David = () => {
  return (
    <div className="px-[2rem] xl:px-[9.125rem] my-[6rem]">
      <div className="bg-[#6D894E] h-[104px] justify-center flex flex-row items-center">
        <h2 className="uppercase text-[white] text-center  p-auto font-[700] text-[24px] md:text-[41px] leading-[35px] md:leading-[55px]">
          Adebowale David - FINANCE CONTROLLER
        </h2>
      </div>
      <div
        className="flex my-[4rem] gap-[5rem] flex-col md:flex-row w-[100%] max-w-[100%] items-center
       justify-between"
      >
        <img
          src={davidbig}
          className="w-[100%] max-w-[100%] md:max-w-[50%] "
          alt="davidbig"
        />
        <div className="w-[100%] flex flex-col justify-between items-center max-w-[100%] md:max-w-[50%]">
          <div className="font-[500] items-start text-[18px] md:text-[20px] leading-[50px] text-black font-[Manrope]">
            <p>
              An accomplished Finance Professional with over 5 years of progressive experience in financial analysis, internal controls, and business administration. Demonstrates expertise in regulatory compliance, financial planning, and risk management across banking and freelance sectors.
            </p>

            <p className="py-[1rem]">
            Proven track record in financial oversight and compliance management, including experience as Internal Control Officer handling CBN returns, AML compliance, and PAYE administration. Successfully transitioned to Human Resource Management, showcasing versatility in operational leadership and process optimization.

            </p>
<p>Combines strong analytical capabilities with business acumen, holding a BSc in Business Administration and professional certifications in Business Planning and Business Continuity. Demonstrates exceptional skills in financial analysis, strategic planning, and team leadership, with a focus on driving operational efficiency and maintaining regulatory compliance.
</p>
          
          </div>
          <div className="border items-end h-[6px]  bg-[#6D894E] border-b-[#6D894E] w-[100%] my-[2rem]"></div>
        </div>
      </div>
    </div>
  );
};

export default David;
